.WalletListItem-img {
  height: auto;
  max-height: 400px;
  width: auto;
  max-width: 200px;
}

.WalletListItem-main-card {
  display: flex;
  border-radius: 20px;
  padding: 20 px;
  flex-direction: column;
  justify-content: center;
  min-height: 200 px;
  height: 400px;
  min-width: 300px;
  max-width: 300px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  margin-bottom: 10px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.results {
  padding-top: 200 px;
  padding: 10px 10px 10px 10px;
  background: rgb(194, 224, 199);
  flex-direction: column;
  min-height: 200 px;
  justify-content: center;
}

.assetDetails {
  color: tomato;
}

.assetDetailsTxt {
  font-weight: bold;
  color: tomato;
}
.connectBtn {
  margin-top: 100px;
}

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px;
}
/* flip the pane when hovered */
.flip-container:hover .flipper,
.flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  width: 320px;
  height: 430px;
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
}

/* hide back of pane during swap */
.front,
.back {
  backface-visibility: hidden;

  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
}
