.Home .lander {
  padding: 10px 0px;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.sectionpad {
  padding-top: 30px;
  padding-bottom: 30px;
}
.sectionpad2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.HomeOrgListItem-img {
  height: auto;
  max-height: 300px;
  width: auto;
  max-width: 200px;
}

.wrapper {
  margin-bottom: -50px;
}

.hght{
  height: 360px;
}

.HomeOrgListItem-main-card {
  display: flex;
  border-radius: 20px;
  padding: 20 px;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  min-height: 200 px;
  height: 400px;
  max-width: 300px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 14px 10px 10px 16px;
  cursor: pointer;
  margin-bottom: 10px;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.NFTListItem-img {
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.imageHolder{
  min-height: 100px;      
  justify-content: center;
  
}
.button {
  padding-bottom: 20px;
}

.NFTListItem-main-card {
  display: flex;
  border-radius: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 200 px;
  height: 400px;
  min-width: 300px;
  max-width: 300px;

  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

  cursor: pointer;
  margin-bottom: 10px;
}

.row {
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.col {
  padding-bottom: 10px;
}

.react-player {
  width: 100%;
  height: 350px;

}