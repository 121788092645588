.Resources .lander {
    padding: 10px 0px;
    text-align: center;
  }
  
  .Resources .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .sectionpad {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .sectionpad2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  