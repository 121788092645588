.NewNote form textarea {
    height: 300px;
    font-size: 1.5rem;
  }

.mainContainer {
  padding-top: 30px;
 
}

.fileChoose{
  height: 100%;
}