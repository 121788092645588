.col{
    max-width: max-content;
}


.buttonDiv{
    padding-bottom: 20px;
}

.tableDiv{
    margin-top: 5px;
}
